import AccountLayout from '../../components/Account/layouts/AccountLayout';
import { pageLinks } from '../../lib/prefetch';
import Dashboard from '../../modules/account/Dashboard';
import { withTranslations } from '~/lib/server/translations';
import { NotFoundResult, compose } from '~/lib/server/staticProps';
import { withRevalidation } from '~/lib/server/revalidation';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';

const composedStaticProps = compose(
  withTranslations(['bridgeStatus', 'trips', 'account', 'common']),
  withRevalidation()
);

export const getStaticProps = composedStaticProps(({ locale }) =>
  pipe(
    locale as string,
    pageLinks,
    TE.fromTask,
    TE.bimap(
      () => ({ notFound: true } as NotFoundResult),
      (dehydratedState) => ({
        props: { dehydratedState },
      })
    )
  )
);

Dashboard.getLayout = function getLayout(page) {
  return (
    <AccountLayout namespace="dashboard" hideMetadata hideNavigation>
      {page}
    </AccountLayout>
  );
};

export default Dashboard;
