import { FC, ReactElement, cloneElement } from 'react';
import { isDashboardEntry } from './utils';
import { useSideNavigation } from '../../../hooks/useSideNavigation';
import { Box, Grid } from '@oresundsbron/bridge-ui';
import { not } from 'fp-ts/lib/Predicate';
import DashboardCard from '../../../components/DashboardCard';
import { useTranslation } from 'next-i18next';

const PrivateDashboard: FC = () => {
  const dashboardCards = useSideNavigation();
  const { t } = useTranslation('account');

  return (
    <Grid className="mt-3 md:mt-6">
      {dashboardCards
        .filter(not(isDashboardEntry))
        .map(({ title, href, icon, key }) => (
          <Box className="col-span-4" key={href}>
            <DashboardCard
              title={title}
              icon={cloneElement(icon as ReactElement, {
                className: 'text-primary-400 text-2xl',
              })}
              href={href}
              preamble={t(`dashboard.card.${key}.description`)}
            />
          </Box>
        ))}
    </Grid>
  );
};

export default PrivateDashboard;
